/*
// .product
*/
@import "../variables";
@import "../functions";
@import "../mixins/hacks";
@import "../mixins/direction";

.product {
}
.product__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: [gallery] calc(50% - 20px) [info] calc(50% - 20px);
    grid-template-rows: auto auto auto auto auto;
    grid-column-gap: 40px;
}
.product__gallery {
    -ms-grid-row: 1;
    -ms-grid-row-span: 6;
    -ms-grid-column: 1;

    @include only-ie() {
        @include direction {
            #{$margin-inline-end}: 40px;
        }
    }

    grid-row-start: 1;
    grid-row-end: 6;
    min-height: 0;
}
.product__info {
    -ms-grid-row: 1;
    -ms-grid-column: 2;

    position: relative;
    min-height: 0;
    .brand_name {
        color: #777;
        &:hover {
            color: $accent-color;
        }
    }
}
.product__sidebar {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.product__footer {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.product__wishlist-compare {
    display: none;
}
.product__name {
    margin-bottom: 10px;
}
.product__rating {
    display: flex;
    margin-bottom: 5px;
}
.product__rating-stars {
    padding-top: 2px;

    @include direction {
        #{$margin-inline-end}: 12px;
    }
}
.product__rating-legend {
    font-size: 14px;
    line-height: 20px;
    color: $rating-legend-font-color;

    a {
        color: inherit;
        transition: color 0.2s;
    }
    a:hover {
        color: $link-hover-color;
    }

    span {
        content: "/";
        padding: 0 7px;
    }
}
.product__description {
    font-size: 16px;
    color: #828282;
}
.product__features {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;

    li {
        padding-top: 1px;
        padding-bottom: 1px;
        position: relative;

        @include direction {
            #{$padding-inline-start}: 13px;
            #{$padding-inline-end}: 0;
        }
    }
    li::before {
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        width: 5px;
        height: 5px;
        border-radius: 2.5px;
        border: 1px solid currentColor;

        @include direction {
            #{$inset-inline-start}: 0;
        }
    }
}
.product__meta {
    list-style: none;
    margin: 12px 0 0;
    padding: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
    color: $body-font-muted-color;
    font-size: 14px;
    border-top: 1px solid $body-divider-color;

    li {
        @include direction {
            #{$margin-inline-end}: 18px;
        }
    }

    a {
        color: inherit;
        transition: color 0.2s;
    }
    a:hover {
        color: $link-hover-color;
    }
}
.product__availability {
    color: #6c757d;
    font-size: 15px;
}
.product__prices {
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 23px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.03em;
    color: $price-color;
}
.product__new-price {
    color: $price-new-color;
}
.product__old-price {
    color: $price-old-color;
    font-weight: $font-weight-normal;
    font-size: 18px;
    text-decoration: line-through;
}
.product__addon-price {
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
    color: #6c757d;
}
.product__option {
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 0;
    }
}
.product__option-label {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    color: $body-font-alt-color;
    padding-bottom: 2px;
}
.product__actions {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    align-items: center;
}
.product__actions-item {
    margin: 4px;
}
.product__quantity {
    width: 120px;
}
.product__footer {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
}
.product__tags {
    @include direction {
        #{$margin-inline-end}: 24px;
        #{$margin-inline-start}: 0;
    }
}
.product__share-links {
    margin-top: 2px;
    margin-bottom: 2px;
}
.whatsapp-enquiry-section {
    padding: 25px 0px;
    margin-top: 15px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000;
    }
    .desc {
        color: #828282;
    }
    .whatsapp-row {
        color: #2e2e2e;
        display: flex;
        margin-top: 10px;
        font-weight: 600;
        width: fit-content;
        &:hover {
            color: #007bff;
        }
    }
    .fa-whatsapp {
        color: #25d366;
        font-size: 23px;
    }
}
.product__actions-share {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    fill: #2e2e2e;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    svg {
        margin: 4px 6px;
    }
}

.share-tooltip {
    .tooltip-inner {
        background-color: #fff;
        color: #000;
        border: 1px solid #f0f0f0;
        box-shadow: 0 0 10px 0 rgba(240, 240, 240, 0.5);
        padding: 12px 20px;
    }
    border-bottom-color: #f0f0f0 !important;

    .arrow {
        color: #f0f0f0 !important;
    }
    .arrow::before {
        color: #f0f0f0 !important;
        border-bottom-color: #f0f0f0 !important;
        border-top-color: #f0f0f0 !important;
    }
}

.share-tooltip {
    position: relative;
    display: flex;
    svg {
        width: 26px;
        height: 26px;
    }
}

.share-tooltip .tooltiptext {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    line-height: 150%;
    color: black;
    text-shadow: white 0 1px;
    background: #fff;
    background-clip: padding-box;
    box-shadow: 0 0px 10px rgba(240, 240, 240, 0.5);
    border: 1px solid #f0f0f0;
    border: 1px solid rgba(240, 240, 240, 0.6);
    border-radius: 3px;
    position: absolute;
    width: 150px;
    left: 0%;
    margin-left: -90px;
    padding: 10px 0px 5px;
    top: 115%;
    margin-bottom: 15px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -ms-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
    transition: 0.25s all ease;
    transition-delay: 0.25s;
    .tooltip-name {
        font-size: 10px;
        @media (max-width: 576px) {
            font-size: 9px;
        }
    }
}

.share-tooltip .tooltiptext::after,
.tooltiptext::before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 0;
    left: 75%;
    margin-left: -10px;
}

.share-tooltip > .tooltiptext:before {
    border-bottom: 10px solid #f0f0f0;
    border-bottom: 10px solid rgba(240, 240, 240, 0.6);
    margin-top: -10px;
}

.share-tooltip > .tooltiptext:after {
    border-bottom: 10px solid #fff;
    margin-top: -8px;
    z-index: 1;
}

.share-tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    transition: 0.25s all ease;
    transition-delay: 0s;
}
.link-circle-box {
    width: 50px;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 4px;
}
.link-circle-icon {
    height: 25px;
    width: 25px;
    border: 1px solid #2e2e2e;
    border-radius: 50%;
    white-space: pre;
    svg {
        color: #2e2e2e;
        fill: #2e2e2e;
        height: 15px;
        width: 12px;
    }
}
@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .product__name {
        font-size: 28px;
        margin-bottom: 10px;
    }
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .product__content {
        -ms-grid-columns: 44% 56%;
        grid-template-columns: [gallery] calc(44% - 15px) [info] calc(56% - 15px);
        grid-column-gap: 30px;
    }
    .product__gallery {
        @include only-ie() {
            @include direction {
                #{$margin-inline-end}: 30px;
            }
        }
    }
    .product__name {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .product__footer {
        display: block;
        margin-top: 18px;
    }
    .product__share-links {
        margin: 12px 0 0;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .product__content {
        display: block;
    }
    .product__gallery {
        margin-bottom: 24px;
    }
    .product__name {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .product__footer {
        margin-top: 22px;
    }
}
@media (max-width: 559px) {
    .product__footer {
        display: block;
        margin-top: 22px;
    }
    .product__share-links {
        margin: 12px 0 0;
    }
}

@import "product--layout--columnar";

.product--layout--sidebar {
    @media (min-width: breakpoint(lg-start)) {
        .product__content {
            -ms-grid-columns: 50% 50%;
            grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
            grid-column-gap: 32px;
        }
        .product__gallery {
            @include only-ie() {
                @include direction {
                    #{$margin-inline-end}: 32px;
                }
            }
        }
        .product__name {
            font-size: 24px;
            margin-bottom: 10px;
        }
        .product__footer {
            display: block;
            margin-top: 18px;
        }
        .product__share-links {
            margin: 12px 0 0;
        }
    }
}

.product--layout--quickview {
    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .product__content {
            grid-template-columns: [gallery] calc(50% - 16px) [info] calc(50% - 16px);
            grid-column-gap: 32px;
        }
        .product__name {
            margin-bottom: 10px;
        }
        .product__footer {
            display: block;
            margin-top: 18px;
        }
        .product__share-links {
            margin: 12px 0 0;
        }
    }
    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .product__content {
            display: block;
        }
        .product__gallery {
            margin-bottom: 24px;
        }
        .product__name {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
}
.variant-box__list {
    .variant-box {
        margin: 0 10px 8px 0;
    }
}

.variant-box {
    border: 1px solid $card-border-color;
    border-radius: 3px;
    padding: 5px 20px;
    cursor: pointer;
    font-weight: 300;
    font-size: 15px;
    position: relative;
    text-transform: capitalize;
    .selected-triangle {
        margin: 0;
        display: none;
    }
    &:hover,
    &.active {
        border: 1px solid $accent-color;
        color: $accent-color;
    }
    &.active {
        .selected-triangle {
            display: block;
        }
        .selected-triangle:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
            height: 10px;
            background: linear-gradient(to bottom left, $accent-color 50%, transparent 50%);
            border-top-right-radius: 1px;
        }
    }
    &.disabled {
        border: 1px solid #f8f8f8;
        background-color: #f8f8f8;
        color: #d8d8d8;
        cursor: not-allowed;
    }
}

.stock-info {
    padding: 10px 20px;
    color: #b3b3b3;
    font-size: 13px;
}

.product__new-price {
    color: $price-new-color;
    margin: 0 2px 2px 2px;
}
.product__old-price {
    color: $price-old-color;
    font-weight: $font-weight-normal;
    font-size: 18px;
    text-decoration: line-through;
    margin: 0 2px 2px 0px;
}

.product__option-label {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;
    text-transform: capitalize;
    white-space: pre;
    font-size: 14px;
    padding-bottom: 4px;
}
.product__option-subtext {
    font-size: 13px;
    color: #b3b3b3;
    padding: 0 4px;
    font-weight: $font-weight-normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.option-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 5px;
    margin-bottom: 2px;
    .additional-price {
        font-weight: $font-weight-normal;
        color: #6c757d;
        white-space: pre;
    }
    .required {
        color: #4fc9b8;
        padding-right: 20px;
        text-transform: lowercase;
    }
}
.addon-qty-input {
    width: 60px;
    .input-number__input {
        -moz-appearance: textfield;
        display: block;
        width: 100%;
        min-width: auto;
        padding: 0 20px 1px;
        text-align: center;
        background-color: white !important;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
    .input-number__sub,
    .input-number__add {
        width: 17px;
    }
}
.exclusive-outline-label {
    color: #2ca1ef;
    margin: 0 4px;
    border-radius: 3px;
    padding: 3px 8px;
    text-transform: uppercase;
    font-size: 11px;
    border: 1px solid #2ca1ef;
    height: 100%;
}
.discount-badge {
    font-size: 12px;
    text-transform: uppercase;
    background: #f8941d;
    color: #fff;
    margin-left: 12px;
    border-radius: 2px;
    padding: 3px 5px;
    font-weight: $font-weight-medium;
    line-height: 1;
    white-space: nowrap;
    letter-spacing: normal;
    position: relative;
    top: -3px;
}
