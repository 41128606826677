$local-dots-height: 18px;
$local-dots-padding: 4px;
$local-dot-size: $local-dots-height - $local-dots-padding * 2;

.announcement-slideshow {
    margin-bottom: $block-margin-bottom;
    position: relative;

    .slick-slide > div > div {
        vertical-align: middle;

        &:focus {
            outline: none;
        }
    }

    .slick-dots {
        width: auto;
        position: absolute;
        // background: $block-slideshow-dots-bg;
        padding: $local-dots-padding;
        border-radius: $local-dots-height / 2;
        font-size: 0;
        list-style: none;
        margin: 0;

        @include direction {
            transform: translateX(-50% * $transform-direction);
            #{$inset-inline-start}: 50%;
        }

        li {
            display: inline-block;
        }

        button {
            width: $local-dot-size;
            height: $local-dot-size;
            padding: 0;
            border: none;
            border-radius: $local-dot-size / 2;
            background: rgb(224, 224, 224);

            &:focus {
                outline: none;
            }

            &:hover {
                background: $block-slideshow-dot-hover-color;
            }
        }
        .slick-active button {
            background: $block-slideshow-dot-active-color;
        }

        li + li {
            margin-left: 10px;
        }
    }
}

.announcement-item {
    text-align: center;
    img {
        width: 100%;
        padding: 20px;
    }
}
