// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
a {
    &:hover {
        color: $accent-color;
    }
}
input[type="text"],
input[type="button"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
a[type="button"]{
    -webkit-appearance: none;
}
.text-color-theme {
    color: $accent-color;
}
.c-pointer {
    cursor: pointer;
}
.background-gray {
    background-color: #f5f5f5;
}
.link-button {
    cursor: pointer;
    color: $accent-color;
    &:hover {
        text-decoration: underline;
    }
}
.hover12 figure {
    background: $accent-color;
    border: $round-border;
    margin: 0 0 0;
}
.hover12 figure img {
    opacity: 1;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.hover12 figure:hover img {
    opacity: 0.5;
}
.react-tel-input {
    .form-control {
        width: 100% !important;
        border-radius: 2px !important;
        height: inherit;
    }
}
.tnC-content {
    padding: 10px 25px;
    height: 420px;
    overflow: auto;
    ol {
        padding-inline-start: 18px;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        padding: 10px 10px;
    }
}
.slider-arrow {
    position: absolute;
    z-index: 5;
    top: 18%;
    padding: 13px;
    cursor: pointer;
    fill: #4c4c4c;

    svg {
        width: 20px;
        height: 20px;
        position: relative;
    }

    &.left {
        left: 35px;
        svg {
            left: -1px;
        }
    }
    &.right {
        right: 35px;
        svg {
            right: -1px;
        }
    }

    @media (max-width: 579px) {
        &.left {
            left: 20px;
        }
        &.right {
            right: 20px;
        }
    }
}

button:focus {
    outline: none;
}

.load-more-btn {
    outline: none !important;
    color: $accent-color;
    display: inline-flex;
    background-color: #ffffff;
    padding: 6px 20px;
    border: 1px solid $card-border-color;
    align-items: center;
    transition: all $local-transition-duration ease-in-out;

    svg {
        fill: $accent-color;
        margin-right: 10px;
    }

    &:hover {
        color: #ffffff;
        display: inline-flex;
        background-color: $accent-color;
        svg {
            fill: #ffffff;
        }
    }
}
.round-label {
    background: #808080;
    color: #fff;
    width: 100px;
    border-radius: 13px;
    height: auto;
    font-size: 13px;
    padding: 3px;
    text-align: center;
    margin-left: auto;
}
.light-background-section {
    background-color: $backgroud-gray;
    padding: 80px 15px;
    @media (max-width: 576px) {
        padding-top: 20px;
    }
}
.title1 .title-inner1 {
    font-size: 25px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    text-transform: none;
    color: #003128;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    @media (max-width: 580px) {
        font-size: 21px;
    }
}
.title1 .title-inner1:after {
    position: absolute;
    height: 5px;
    width: 70px;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}
.service-container {
    text-align: center;
}
.service-title {
    font-size: 21px;
    font-weight: 600;
    color: #003128;
    margin-top: 15px;
}

.service-desc {
    font-size: 16px;
    line-height: 28px;
    color: #636363;
}
.uhome-section2 {
    padding: 55px 0;
    @media (max-width: 991px) {
        text-align: center;
    }
    @media (max-width: 576px) {
        padding: 35px 10px;
    }
}
.packaging-desc {
    font-size: 16px;
    line-height: 28px;
    color: #636363;
    text-align: center;
}
.packing-title {
    text-align: center;
    text-transform: uppercase;
    color: #003128;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding-top: 10px;
}
.packaging-subtitle {
    font-weight: 300;
    margin-bottom: 30px;
    font-size: 17px;
}
.enquiry-btn {
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
    background-color: transparent;
    color: $accent-color;
    border-radius: 25px;
    width: 150px;
    border: 2px solid $accent-color;
    font-weight: 600;
    &:hover {
        border: 2px solid #fff;
        background-color: $accent-color;
        color: #fff;
    }
    &:focus {
        box-shadow: none;
    }
}

.material-container {
    position: relative;
    text-align: center;
    color: white;
    padding: 0px !important;
    overflow: hidden;
}

.material-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    display: block;
    background: rgba(0, 0, 0, 0.3);
}

.material-container img {
    transition: transform 0.5s;
}

.material-container:hover img {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);
    transition: transform 0.5s;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
}

.material-row {
    margin-bottom: 30px;
}

/** for mobile **/
@media (max-width: 576px) {
    .categories-img {
        padding: 10px;
    }
    .categories-btn {
        padding-top: 5px;
        padding-bottom: 5px;
        min-width: 120px;
        height: 35px;
        font-size: 11px;
    }
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

/** for tablet **/
@media (min-width: 641px) and (max-width: 1024px) {
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

.home-category-box {
    position: relative;
    height: 100%;
    margin-bottom: 15px;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .title-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 20px;
    }
}

.slider-arrow-circle {
    position: absolute;
    z-index: 5;
    top: 30px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.6;
    padding: 8px;
    cursor: pointer;
    fill: #393b3b;

    svg {
        width: 13px;
        height: 13px;
        position: relative;
    }

    &.left {
        left: -15px;
        box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2);
        svg {
            left: -1px;
        }
    }
    &.right {
        right: -15px;
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
        svg {
            right: -1px;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.vproduct-section-2 .container {
    @media (max-width: 768px) {
        max-width: 100%;
    }
}
.vproduct-section-2 {
    background-color: #fff;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 10px 50px 10px;
    @media (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 580px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.vbrands-section .container {
    padding: 30px;
    @media (max-width: 1200px) {
        padding: 0;
    }
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 30px;
    }
    @media (max-width: 580px) {
        max-width: 100%;
        padding: 20px;
    }
    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
}
.vproduct-section-3 {
    background: #fafafa;
    display: flex;
    justify-content: center;
    padding: 80px 130px;
    @media (max-width: 1560px) {
        padding: 50px 0;
    }
    @media (max-width: 1200px) {
        padding: 30px 0;
    }
    @media (max-width: 768px) {
        padding: 20px;
    }
    @media (max-width: 580px) {
        padding: 0px;
    }
}
.vproduct-section-3 .category-box {
    background: #fafafa;
    width: calc(20% - 10px);
    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
        width: calc(25% - 10px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        width: calc(33.33333% - 10px);
    }
    @media (max-width: breakpoint(sm-end)) {
        width: calc(50% - 10px);
    }
}
.vproduct-section-3 .category-box,
.vbrands-section .brand-box {
    border: 1px solid #e8e8e8;
    margin: 0 5px;
    margin-bottom: 10px;
    transition: 0.3s;
}
.vproduct-section-3 .category-box:hover,
.vbrands-section .brand-box:hover {
    box-shadow: 0 0 12px 4px #bfbfbf;
    @media (max-width: 1200px) {
        box-shadow: 0 0 8px 2px #bfbfbf;
    }
}
.category-box .img-wrapper {
    position: relative;
    height:220px;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media (max-width: 480px) {
        height: 170px;
    }
}
.brands-box {
    padding: 5px 10px;
    .img-wrapper {
        height: 85px;
        img {
            object-fit: contain;
        }
    }
}
.vbrands-section .item-detail {
    text-align: center;
    padding: 5px;
    a {
        color: #2e2e2e;
    }
}
.vproduct-section-3 .item-detail {
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    color: #7a7a7a;
    transition: all 0.5s ease;

    a {
        color: #2e2e2e;
        overflow: hidden;
        flex-grow: 1;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.banner-container {
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0px;
    }
}
.no-result-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infinite-scroll-component {
    overflow: hidden !important;
}
.custom-grey-box {
    padding: 15px;
    border-radius: 8px;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}
.custom-grey-box:last-child {
    margin-bottom: 0;
}
.middle-img {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.middle-img .custom-ns-svg {
    height: 50px;
}
.middle-img .custom-ss-svg {
    height: 30px;
}
.custom-normal-p {
    font-size: 14px;
    margin-bottom: .5rem;
}
.custom-fw-300 {
    font-weight: 300;
}
.custom-fc-black {
    color: #000;
}
.custom-list__item {
    flex-basis: 0;
    flex-grow: 1;
}
.custom-list__item--new {
    border-radius: 2px;
    border: 2px dashed #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    margin: 0 0 .5rem 0;
    transition: border-color .2s;
}
.custom-list__plus {
    width: 35px;
    height: 35px;
    border: 2px dashed #bbbbbb;
    position: relative;
    border-radius: 30px;
    // margin-bottom: 12px;
    transition: border-color .2s;
}
html[dir=ltr] .custom-list__plus::before, 
html[dir=ltr] .custom-list__plus::after {
    left: calc(50% - 8px);
}
.custom-list__plus::after {
    transform: rotateZ(90deg);
}
.custom-list__plus::before, 
.custom-list__plus::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 4px;
    background-color: #bbbbbb;
    border-radius: 1.5px;
    top: calc(50% - 2px);
    transition: background-color .2s;
}
.custom-list__item--new:hover {
    border-color: #525252;
}
.custom-list__item--new:hover .custom-list__plus {
    border-color: #525252;

    &::before,
    &::after {
        background-color: #525252;
    }
}
.custom-hr-line {
    border-top: 1px solid gray;
}
.custom-hr-line:first-child {
    border-top: 0 !important;
}
.custom-item-view-link {
    padding: 15px;
    transition: background-color .2s;
    border-radius: 3px;
    cursor: pointer;
}
.custom-item-view-link:hover {
    background-color: #dbdbdb;
}
.custom-currency-select {
    width: 20%;
    display: inline-block;
    @media (max-width: 768px) {
        width: 30%;
    }
}
.custom-amount-input {
    width: 80%;
    display: inline-block;
    @media (max-width: 768px) {
        width: 70%;
    }
}
/* Chrome, Safari, Edge, Opera */
.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.custom-number-input[type=number] {
    -moz-appearance: textfield;
}
.custom-attachment-list {
    border-top: 1px solid gray;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.custom-attachment-list:last-child {
    border-bottom: 1px solid gray;
}
.custom-remove-btn {
    font-weight: 700;
    font-size: 14px;
}
.protector-tabs__items {
    font-size: 14px;
    padding: 7px 14px;
}
.protector-tabs__table {
    width: 100%;
    font-size: 14px;
}
.protector-tabs__table tbody th,
.protector-tabs__table tbody td{
    padding-top:10px;
    padding-bottom:10px;
}
.protector-tabs__table tbody th{
    width: 155px;
    padding-right: 5px;
}
.protector-tabs__table-line {
    border-top: 1px solid;
    padding-top: 15px;
}