// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
// @import "themes/red";
//@import 'themes/blue';
//@import 'themes/black';
@import "themes/green";

// $light-accent-color: #98c93d;
$round-border: #dddddd;
$nav-panel-bg: #fff;
$dark-gray: #2f2f2f;
$medium-gray: #646464;
$light-gray: #979797;
$backgroud-gray: #fafafa;
$mobile-header-menu-button-default-bg: $accent-color !default;
$border-grey: #f1f5f4;
$footer-bg: #212C38;
$font-weight-medium: 600;
