/*
// .page-header
*/
@import "../variables";
@import "../functions";

.page-header {
}
.page-header__container {
    padding-bottom: 0;
}
.page-header__breadcrumb {
    padding-top: 16px;
}
.page-header__title {
    padding: 5px 26px 0;
    h1 {
        margin-bottom: 0;
    }

    .description {
        margin-top: 8px;
        color: #727272;
        font-size: 15px;
    }
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .page-header__title {
        padding: 4px 0 0;

        h1 {
            margin-bottom: 0;
        }
    }
}
@media (max-width: breakpoint(sm-end)) {
    .page-header__container {
        padding-bottom: 0;
    }
    .page-header__title {
        padding: 2px 0 0;

        h1 {
            margin-bottom: 0;
            font-size: 30px;
        }
    }
}
